<script setup lang="ts">
const props = defineProps<{
  link: string
  text: string
  image: string

}>()
const drawers = useDrawers()

function handleClose() {
  drawers.value.navigation.open = false
}
</script>

<template>
  <li>
    <NuxtLinkLocale
      data-testid="link"
      :to="props.link"
      @click="handleClose"
    >
      <div class="relative h11 flex items-center justify-between gap-2 of-hidden rd bg-slate-100 px2">
        <slot>
          <span class="line-clamp-2 grow-1 items-center text-ellipsis break-anywhere text-center text-base text-slate-900 fw700 leading-snug hyphens-auto">
            {{ props.text }}
          </span>
        </slot>
        <div class="none absolute right-1.5 top-1.5 h8 w8 op-40 hidden">
          <NuxtImg
            height="100"
            width="100"
            decoding="async"
            loading="lazy"
            :src="props.image"
            :alt="props.text"
            data-testid="collectionImageView"
          />
        </div>
      </div>
    </NuxtLinkLocale>
  </li>
</template>

<script setup lang="ts">
interface CollectionNavigationItem {
  link: string
  text: string
  image: string
}

interface CollectionNavigationConfig {
  [categoryId: string]: CollectionNavigationItem[]
}

interface Props {
  currentRootCollectionId: string
  categoryConfig: CollectionNavigationConfig
}

const props = defineProps<Props>()
const { t } = useI18n()
const currentCategoryItems = computed(() => {
  return props.categoryConfig[props.currentRootCollectionId] || []
})
</script>

<template>
  <menu
    class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]"
    data-testid="firstLevelCollectionNavigation"
  >
    <template v-for="item in currentCategoryItems" :key="item.link">
      <CollectionTreeNavigationItemHref
        :link="item.link"
        :text="t(item.text)"
        :image="item.image"
      />
    </template>
  </menu>
</template>
